.cs-navbar-black {
  background:#0b1011;
  color: 'black' !important;
  box-shadow: none !important;
}

.cs-nav-link {
  font-size: 18px;
  font-weight: 400;
}

.cs-nav-link:focus {
  color: '#f5593d' !important;
}

.cs-nav-link:hover {
  cursor: pointer;
}

.cs-header {
  font-weight: 400;
}

.arrow {
  margin: 5% 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.arrow:hover {
  cursor: pointer;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.cs-card-img {
  max-height: 30vh;
}

.cs-phone {
  font-weight: bold !important;
}
.navbar .navbar-collapse:after {
  background:#0b1011;
}

.btn-secondary:hover > a {
  color: white !important;
}

.cs-blur {
  filter: blur(2px);
  overflow: hidden;
}

.modal {
  display: block; 
  position: fixed;
  z-index: 1000; 
  left: 0;
  top: 0;
  width: 100vw; 
  height: 100vh;
  overflow: auto; 
  background-color: rgba(0,0,0,0.9);
}

.modal-content {
  background-color: transparent;
  margin: 5% auto;
  padding: 20px;
  width: 90%;
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: fixed;
  left: 0;
  top: 0;
  padding: 3% 5%;
  z-index: 10;
}

.close:hover,
.close:focus {
  cursor: pointer;
  color:rgb(245, 89, 61)
}

.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  border: 3px solid rgb(245, 89, 61);
  border-radius: 10px;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.border-error {
  border: 2px solid rgb(245, 89, 61);
  border-radius: 5px;
}

span > svg.svg-inline--fa {
  width: 45% !important;
}

@media only screen and (max-width: 1170px) {
  span > svg.svg-inline--fa {
    width: 60% !important;
  }
}
